<template>
  <div class="xiangqing">
    <!-- <Header
      style="position: relative; background: rgba(29, 29, 29, 1)"
      :neesscrollbool="false"
    ></Header> -->

    <div ref="header">
      <Header :bg="bg"></Header>
    </div>
    <!-- <div class="bg"></div> -->

    <div class="daohang">
      <div class="nav">
        <div class="weizhi">你的位置：</div>
        <div class="mingxi">
          <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="mianbaoxie"
          >
            <el-breadcrumb-item :to="{ path: '/index' }"
              >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item :to="{ path: '/pressCenter' }"
              >新闻中心</el-breadcrumb-item
            >
            <el-breadcrumb-item class="active">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="xinwen">公司新闻</div>
    </div>

    <div class="transfer">
      <div class="left">
        <div class="title">{{ data.title }}</div>
        <div class="fabu">
          发布时间：<span>{{ data.yers + "-" + data.time }}</span>
        </div>
        <div class="dizhi">地址：成都</div>
        <!-- <div class="text">{{ data.text }}</div> -->
        <div class="text" v-html="data.text"></div>
      </div>
      <div class="transfer-right">
        <img class="fangda" :src="data.imgs[index].src" alt="" />
        <div class="lunbo" v-if="data.imgs.length > 1">
          <el-carousel
            class="xwxq-lunbo"
            :interval="4000"
            type="card"
            height="200px"
            @change="imgchange"
          >
            <el-carousel-item v-for="(item, index) in data.imgs" :key="index">
              <img class="img" :src="item.src" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="more">
      <div class="title">更多咨询</div>
      <div class="box">
        <div
          class="son"
          v-for="(item, index) in list"
          :key="index"
          @click="goto(item)"
        >
          <img class="img" :src="item.src" alt="" />
          <div class="title">
            <span>公司新闻 丨</span
            >{{
              item.title.length > 22
                ? item.title.slice(0, 20) + " . . ."
                : item.title
            }}
          </div>
          <div
            class="text"
            v-html="
              item.text.length >= 40
                ? item.text.slice(0, 38) + ' . . .'
                : item.text
            "
          ></div>
          <div class="time">
            {{ item.time }} <span>{{ item.yers }}</span>
          </div>
          <em></em>
          <div class="jiantou">></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../../components/Header.vue";
import Footer from "../../../components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      list: [
        {
          src: require("../../../assets/imgs/xinwen/7.jpg"),
          title: "中标资讯： 热烈祝贺成都汉康中标1000万级小型水库监测预警项目",
          text: "热烈祝贺成都汉康中标安徽省六安市裕安区1000万级小型水库雨水情自动测报系统建设项目。</br></br>本项目位于安徽省六安市裕安区境内，小型水库大多位于偏僻位置且交通不便，安全隐患大、管理人员少。本项目采用信息技术，实现对各小型水库雨水情信息的自动采集、传输、分析和呈现，实现对全区范围内水库雨情、水情、水位进行实时在线监测预警，与省市县互联互通、信息共享，实现小型水库雨水情测报全覆盖，落实水库防汛“信息精准、指挥精准、措施精准”目标要求，为水库防汛指挥调度、预报预警提供信息保障。",
          time: "4-22",
          yers: "2021",
          imgs: [{ src: require("../../../assets/imgs/xinwen/7.jpg") }],
        },
        {
          src: require("../../../assets/imgs/xinwen/8.jpg"),
          title: "中标资讯：成都汉康携手阿里云斩获3000万级软件大单",
          text: "热烈祝贺成都汉康携手阿里云，中标成都市智慧交通空间地理信息基础支撑及服务系统建设一张图项目。</br></br>本项目由成都交通投资集团投资，采用汉康GIS+BIM大数据技术和高德地图路网数据，为成都市智慧交通数据中心平台、交通运输行业应用平台提供标准的、全面的、权威的空间地理信息基础资源服务，打造成都市智慧交通规划建设一张图，打通城市交通数据神经系统和城市交通疏导系统，提供公共出行调度系统、智能引擎规划系统和无人驾驶高精地图服务。成为未来智慧交通大脑空间地理信息基础支撑平台。</br></br>业主简介：成都交通投资集团是成都市政府独资市级交通基础设施投融资的大型国有综合交通企业集团，主要承担成都市交通基础设施项目的投融资、开发建设和经营管理工作。目前，成都交投集团按照“功能化、专业化、市场化、国际化”的发展要求已形成了公路、铁路、航空三大核心产业，实业开发、运业物流、智慧停车、智能交通四大交通配套业务的3+4产业布局，按照主业突出、专业分工、协同发展的原则改组形成集团总部、12家二级子集团、60余家三级子公司的管理体系，员工6000余人，已经成为中西部综合交通领军企业集团。",
          time: "4-22",
          yers: "2021",
          imgs: [
            { src: require("../../../assets/imgs/xinwen/8.jpg") },
            { src: require("../../../assets/imgs/xinwen/8-1.jpg") },
          ],
        },
      ],
      data: {},
      timer: null,
      arr: [],
      bg: "black",
      set1: {
        arrows: true,
        dots: true,
        infinite: true,
        centerMode: true,
        centerPadding: "0px",
        // slidesToShow: 1,
        // slidesToScroll: 1,
        variableWidth: true,
        touchMove: false,
      },
      index: 0,
      xinwenObj: {},
    };
  },
  created() {
    //console.log("this.$route11 :>> ", this.$route.params);

    this.xinwenObj = JSON.parse(sessionStorage.getItem("xinwen"));
    this.data = Object.keys(this.$route.params).length
      ? this.$route.params
      : this.xinwenObj;

    // console.log("data :>> ", this.data);
  },
  methods: {
    goto(v) {
      this.data = v;
      window.sessionStorage.setItem("xinwen", JSON.stringify(v));
      document.body.scrollTop = 0;
    },
    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //  console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          //控制导航条

          this.$refs.header.style.position = "absolute";
          this.$refs.header.style.top = "0";
          this.$refs.header.style.left = "0";
          this.$refs.header.style.zIndex = "100";
        } else {
          // console.log("往上滑");
          if (scroll > 300) {
            this.$refs.header.style.position = "fixed";
            this.$refs.header.style.top = "0";
            this.$refs.header.style.left = "0";
            this.$refs.header.style.zIndex = "100";
            this.bg = "black";
          }
        }
      }
      if (scroll <= 280) {
        this.$refs.header.style.position = "absolute";
        this.$refs.header.style.top = "0";
        this.$refs.header.style.left = "0";
        this.$refs.header.style.zIndex = "100";
      }
    },

    imgchange(index) {
      this.index = index;
    },
  },
  mounted() {
    document.body.scrollTop = 0;

    window.addEventListener("scroll", this.scroll, true);
  },
  beforeDestroy() {
    // this.time=null
    window.removeEventListener("scroll", this.scroll, true);
  },
};
</script>

<style lang="scss" scoped>
.xiangqing {
  width: 1920px;
  margin: 0 auto;
  .bg {
    width: 100%;
    height: 578px;
    background-image: url("../../../assets/imgs/xinwen/bg2.png");
    background-size: 100% 100%;
  }
  .daohang {
    width: 100%;
    // height: 208px;
    background-color: rgba(247, 248, 250, 1);
    position: relative;
    padding-top: 64px;
    font-family: Alibaba PuHuiTi Xi;
    .nav {
      height: 144px;
      padding: 50px 260px;
      display: flex;
      //flex-direction: column;
      align-items: center;
      position: relative;
      bottom: 20px;
      .weizhi {
        // display: flex;
        // align-items: center;
        font-size: 12px;
        font-weight: 400;
        color: rgba(196, 196, 196, 1);
      }
    }
    .xinwen {
      width: 100%;
      padding: 0 260px;
      font-size: 26px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      color: #333333;
      // margin-bottom: 50px;
      position: absolute;
      bottom: 20px;
    }
  }

  .transfer {
    width: 1920px;
    // height: 980px;
    padding: 60px 260px;
    display: flex;
    justify-content: space-between;

    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;

    color: #888888;

    opacity: 1;
    .left {
      width: 664px;
      .title {
        font-size: 20px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #333333;
        opacity: 1;
        margin-bottom: 28px;
      }
      .fabu {
        margin-top: 20px;
        font-family: Alibaba PuHuiTi Zx;
      }
      .dizhi {
        margin-bottom: 30px;
        font-family: Alibaba PuHuiTi Zx;
      }
      .text {
        border-top: 1px solid rgba(235, 235, 235, 1);
        padding: 30px 0;
      }
    }
    .transfer-right {
      width: 650px;
      .fangda {
        width: 100%;
        height: 378px;
      }
      .lunbo {
        width: 100%;
        height: 200px;
      }
    }
  }
  .more {
    width: 1920px;
    height: 800px;
    background: rgba(251, 251, 251, 1);
    opacity: 1;
    padding: 60px 260px;
    .title {
      font-size: 26px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      margin-top: 20px;
      color: #333333;
      opacity: 1;
    }
    .box {
      // width: 1920px;
      // margin: 0 auto;
      // padding: 80px 260px;
      margin-top: 80px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .son {
        width: 656px;
        height: 520px;
        // background: rgba(170, 170, 170, 0.39);
        opacity: 1;
        position: relative;
        cursor: pointer;
        .img {
          width: 100%;
          height: 338px;
        }
        .title {
          margin-top: 20px;
          font-size: 20px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 400;
          color: #333333;
          span {
            font-weight: 700;
          }
        }
        .text {
          margin-top: 18px;
          font-size: 16px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 400;
          line-height: 30px;
          color: #888888;
          opacity: 1;
        }
        .time {
          margin-top: 46px;
          font-size: 20px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 400;

          color: #c8c8c8;
          opacity: 1;
          span {
            font-size: 14px;
            font-family: Alibaba PuHuiTi Xi !important;
            font-weight: 400;

            color: #c8c8c8;
            opacity: 1;
          }
        }
        em {
          position: absolute;
          left: 0;
          bottom: 70px;
          width: 100%;
          height: 1px;
          background-color: #ebebeb;
        }
        .jiantou {
          position: absolute;
          bottom: 24px;
          right: 40px;
          font-size: 18px;
          font-family: Alibaba PuHuiTi Xi !important;
          font-weight: 700;

          color: #c8c8c8;
          opacity: 1;
        }
      }
      .son:hover .title {
        color: #3067ff;
      }
    }
  }
}
</style>